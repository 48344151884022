@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

$ending-margin: 3em;
$yellow: #e4fa68;

@mixin hover {
  color: #e4fa68;
  border: 3px solid #e4fa68;
  animation: flash 0.5s infinite;
}

@mixin flashy-link {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &:hover,
  &:focus,
  &:hover:before,
  &:focus:before {
    color: #e4fa68;
    border: 2px solid #e4fa68;
    animation: flash 0.5s infinite;
  }
}

@mixin link {
  text-decoration: none !important;
  color: #fff;
  padding: 1em;
}

#root {
  width: 100dvw;
  height: 100dvh;
  display: grid;
  grid-template-rows: auto min-content;
  gap: 1em;
  overflow: auto;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #1e1e1e;
  color: #fff;
  font-family: 'Press Start 2P';
  text-transform: uppercase;
}

main {
  width: clamp(250px, 800px, 100%);
  position: relative;
  margin: auto;

  .mute {
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
  }

  .skip {
    font-size: 0.5rem;
    text-align: center;
    padding: 1em;
    animation: skip 1s infinite;
  }
}

.start-menu {
  min-height: 175px;
  .skip {
    position: static;
  }

  .mute {
    left: 50%;
    bottom: -3em;
    transform: translate(-50%, -50%);
    width: max-content;
  }
}

section {
  #home {
    position: absolute;
    left: 50%;
    bottom: 0.5em;
    transform: translate(-50%);
    @include flashy-link();
    @include link();
  }

  .skip {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 0.5rem;
    padding: 1em;
    animation: skip 1s infinite;
  }
}

.about {
  min-height: 300px;
  img {
    width: 200px;
    float: left;
    margin: 0 1em 0 0;
  }

  .about-text {
    margin-bottom: calc($ending-margin + 1rem);
  }

  #resume {
    color: $yellow;
    font-size: 1rem;
  }
}

.start-text {
  text-align: center;
  animation: flash 0.5s infinite;
}

h1 {
  text-align: center;
  line-height: 2;
  max-width: 100%;
}

ul {
  list-style: none;
  padding: 0;

  li {
    text-align: center;
    margin-bottom: 2em;
  }
  a {
    text-decoration: none !important;
    color: #fff;
    position: relative;
    padding: 1em;
    @include flashy-link();
  }
}

@keyframes flash {
  from {
    color: #fff;
    border-color: #fff;
  }
  to {
    color: #e4fa68;
    border-color: #e4fa68;
  }
}

@keyframes skip {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pixel-corners {
  clip-path: polygon(0px calc(100% - 20px), 4px calc(100% - 20px), 4px calc(100% - 12px), 8px calc(100% - 12px), 8px calc(100% - 8px), 12px calc(100% - 8px), 12px calc(100% - 4px), 20px calc(100% - 4px), 20px 100%, calc(100% - 20px) 100%, calc(100% - 20px) calc(100% - 4px), calc(100% - 12px) calc(100% - 4px), calc(100% - 12px) calc(100% - 8px), calc(100% - 8px) calc(100% - 8px), calc(100% - 8px) calc(100% - 12px), calc(100% - 4px) calc(100% - 12px), calc(100% - 4px) calc(100% - 20px), 100% calc(100% - 20px), 100% 20px, calc(100% - 4px) 20px, calc(100% - 4px) 12px, calc(100% - 8px) 12px, calc(100% - 8px) 8px, calc(100% - 12px) 8px, calc(100% - 12px) 4px, calc(100% - 20px) 4px, calc(100% - 20px) 0px, 20px 0px, 20px 4px, 12px 4px, 12px 8px, 8px 8px, 8px 12px, 4px 12px, 4px 20px, 0px 20px);
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 200px auto auto;
  gap: 1em;
  margin-bottom: $ending-margin;
}

.card {
  display: grid;
  grid-row: span 3;
  grid-template-rows: subgrid;
  border: 3px solid #fff;
  font-size: 14px;
  padding: 1em;
  text-align: center;
  margin-bottom: 2em;

  img {
    width: 200px;
    max-width: 100%;
    margin: 0 auto;
  }

  p {
    font-size: 12px;
  }

  &:hover,
  &:focus {
    @include hover;
  }
}

.contact {
  min-height: 200px;
  form {
    display: grid;
    gap: 0.5em;
    margin-bottom: calc($ending-margin + 1rem);

    input,
    textarea {
      font-family: inherit;
      padding: 0.5em;
      width: 100%;
    }

    .required {
      color: #e4fa68;
      animation: flash 0.5s infinite;
    }

    button {
      font-family: inherit;
      padding: 1em;
      text-transform: uppercase;
      width: 100%;
    }
  }
  .status-message {
    text-align: center;
    margin-top: 5em;
  }
}

.footer {
  font-size: 12px;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  width: clamp(250px, 800px, 100%);
  margin: 0 auto;

  .socials {
    display: flex;
    gap: 1em;
  }
}

@media (max-width: 499px) {
  h2 {
    text-align: center;
  }

  a {
    font-size: 14px;
  }

  .about {
    display: grid;
    grid-template-rows: auto;

    .about-text {
      margin-top: 1em;
    }

    img {
      margin: 0 auto;
    }
  }

  section .skip {
    position: static;
    display: block;
    margin-bottom: $ending-margin;
  }
}
